import { Box } from '@mui/material';
import { Header } from '@travel-platform/commons/src/components/common/header';
import useDevice, { DeviceEnum } from '@travel-platform/commons/src/hook/use-device';
import Footer from 'components/common/footer';
import NavigationBottom from 'components/landing/components/navigation-bottom/navigation-bottom';
import { FC, ReactNode } from 'react';
import { Routes } from 'routes';
import { HeaderItems } from 'constants/header-menu';

type LandingProp = {
    children: ReactNode;
    fluidContainer?: boolean;
};
export enum LANDINGS {
    HOTEL = 'hotel',
    FLIGHT = 'flight',
    TOUR = 'tour',
    VISA = 'visa',
}

export const SimpleLayout: FC<LandingProp> = ({ children, fluidContainer }) => {
    const device = useDevice();
    const isMobile = device === DeviceEnum.MOBILE;
    const { headerMenuList, languages } = HeaderItems();

    return (
        <>
            {isMobile && <NavigationBottom />}

            <Header
                routes={Routes}
                headerMenu={headerMenuList}
                languages={languages}
            />
            <Box
                mt={{ xs: '50px', md: '56px' }}
                className={fluidContainer ? '' : 'container'}>
                {children}
            </Box>

            <Footer />
        </>
    );
};

export default SimpleLayout;
