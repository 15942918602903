import { Button } from '@mui/material';
import { styled } from '@mui/system';
import palette from '../../common/style/color.module.scss';
export const ButtonModified = styled(Button)({
    '&.MuiButton-root': {
        fontWeight: '800 ',
        fontSize: '14px ',
        lineHeight: '24px ',
        boxShadow: `0 0 0 0 ${palette.black}`,
        borderRadius: '8px',
    },
    '&.MuiButton-text': {
        border: '0px',
    },
});
