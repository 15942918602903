import { BottomNavigation, Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginShowDialog } from '@travel-platform/commons/src/redux/login-slice';
import NavigationItem from './components/navigation-item/navigation-item';
import styles from './navigation-bottom.module.scss';
import { NavigationBottomProps } from './navigation-bottom.types';
import { NavigationItemProps } from './components/navigation-item/navigation-item.types';
import WhatsappMassenger from '../../../common/whatsapp-massenger/whatsapp-massenger';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';
import { SITE_URL } from '@travel-platform/commons/src/components/common/constant/constant';
import { useRouter } from 'next/router';

export default function NavigationBottom(props: NavigationBottomProps) {
    const dispatch = useDispatch();
    const languageJson = useLanguage();
    const [show, setShow] = useState(true);
    const scrollY = useRef(0);
    const router = useRouter();

    useEffect(() => {
        if (window) window.addEventListener('scroll', scrollDirection);
        return () => window.removeEventListener('scroll', scrollDirection);
    }, []);
    const scrollDirection = (e: Event) => {
        if (scrollY.current < window.scrollY) {
            setShow(true);
        } else {
            setShow(true);
        }
        scrollY.current = window.scrollY;
    };
    const navigationItems: NavigationItemProps[] = [
        {
            icon: 'icon-home',
            iconActive: 'icon-home-active',
            url: process.env.NEXT_PUBLIC_HOME_PAGE_LANDING_URL!,
            text: languageJson.common.home,
        },
        {
            icon: 'icon-receipt-item',
            iconActive: 'icon-receipt-item-active',
            url: `${SITE_URL}/${router.locale}/dashboard/track-order`,
            text: languageJson.common.track_order,
            needsLogin: true,
            showLoginModal: () => dispatch(loginShowDialog(true)),
        },
        {
            icon: 'icon-profile-circle',
            iconActive: 'icon-profile-circle-active',
            url: `${SITE_URL}/${router.locale}/dashboard`,
            text: languageJson.common.account,
            needsLogin: true,
            showLoginModal: () => dispatch(loginShowDialog(true)),
        },
    ];

    return (
        <>
            <Box className={styles.whatsappMassenger + ' ' + (show && styles.margin)}>
                <WhatsappMassenger />
            </Box>
            <BottomNavigation
                className={styles.bottomNavigation + ' ' + (show && styles.active)}
                showLabels
                value={0}>
                {navigationItems.map((navigationItem, index) => (
                    <NavigationItem
                        key={index}
                        needsLogin={navigationItem.needsLogin}
                        showLoginModal={navigationItem.showLoginModal}
                        icon={navigationItem.icon}
                        iconActive={navigationItem.iconActive}
                        url={navigationItem.url}
                        text={navigationItem.text}
                    />
                ))}
            </BottomNavigation>
        </>
    );
}
