import { Grid } from '@mui/material';
import Chart from './icons/chart';
import CreditCard from './icons/credit-card';
import Dialog from './icons/dialog';
import { FeatureTileProp, FeatureTile } from '@travel-platform/commons/src';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';

const LandingFeatureTiles = () => {
    const languageJson = useLanguage();

    const data: FeatureTileProp[] = [
        {
            icon: <CreditCard />,
            title: languageJson.common.feature_tile.credit.title,
            description: languageJson.common.feature_tile.credit.desc,
        },
        {
            icon: <Chart />,
            title: languageJson.common.feature_tile.chart.title,
            description: languageJson.common.feature_tile.chart.desc,
        },
        {
            icon: <Dialog />,
            title: languageJson.common.feature_tile.dialog.title,
            description: languageJson.common.feature_tile.dialog.desc,
        },
    ];
    return (
        <Grid
            container
            columnSpacing={{ xs: 0, md: 3 }}
            rowSpacing={{ xs: 3, md: 0 }}>
            {data.map(item => (
                <Grid
                    key={item.title}
                    item
                    xs={12}
                    md={4}>
                    <FeatureTile {...item} />
                </Grid>
            ))}
        </Grid>
    );
};
export default LandingFeatureTiles;
