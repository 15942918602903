import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../redux/hooks';
import { searchesFlightClear, SearchesFlightState } from '../../../../redux/flight-searches-slice';
import RecentSearchCard from '../../../common/recent-search-card/recent-search-card';
import styles from './recent-search-section.module.scss';
import { RecentSearchSectionProps } from './recent-search-section.types';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';

export default function RecentSearchSection(props: RecentSearchSectionProps) {
    const recentSearches: SearchesFlightState = useAppSelector(state => state.flightSearches);
    const dispatch = useDispatch();
    const languageJson = useLanguage();

    return (
        <Box className={styles.recentSearchSection}>
            {recentSearches.flightSearches && recentSearches.flightSearches.length > 0 && (
                <>
                    <Typography
                        component={'div'}
                        className={styles.label}>
                        <Box className={styles.text}>{languageJson.common.recent_searches}</Box>
                        <Box className={styles.icon}>
                            <span className='icon-clock-filled'></span>
                        </Box>
                        <Box
                            className={styles.delete}
                            onClick={() => dispatch(searchesFlightClear())}>
                            <Box className={styles.icon}>
                                <span className='icon-trash'></span>
                            </Box>
                            <Box className={styles.text}>{languageJson.common.delete}</Box>
                        </Box>
                    </Typography>
                    <Box className={styles.cards}>{recentSearches.flightSearches.map((recentSearch, index) => index < 4 && <RecentSearchCard data={recentSearch} />)}</Box>
                </>
            )}
        </Box>
    );
}
