import React from 'react';
import palette from '../../../common/style/color.module.scss';
import { Box, Dialog, Drawer, IconButton, Stack, Typography, type SxProps, type Theme, Button } from '@mui/material';
import useDevice, { DeviceEnum } from '@travel-platform/commons/src/hook/use-device';
import { PopUpTourActionProps } from './pupup.types';

type FormsRef = {
    onSubmit?: () => void;
    closePopUp?: (_open: boolean) => void;
};
const dialogStyles: SxProps<Theme> = {
    '& .MuiBackdrop-root': {
        background: '#F4F4F4',
        opacity: '0.75 !important',
    },
};
const paperPropsStyles: SxProps<Theme> = {
    p: 2,
    borderRadius: 4,
    background: 'white',
    boxShadow: '0 0 0 0',
    border: `1px solid ${palette.primary}`,
};

const iconMenuStyle: SxProps<Theme> = {
    width: 40,
    height: 40,
    minWidth: 40,
    borderRadius: 2,
    border: '1px solid',
    p: 0,
};

export const PopUpTourWrapper = React.forwardRef<FormsRef, PopUpTourActionProps>((props, ref) => {
    // Destructure Props
    const { title, body, buttonAction, buttonText }: PopUpTourActionProps = props;

    // States
    const [open, setOpen] = React.useState<boolean>(false);

    // Use Hooks
    const isMobile = useDevice() === DeviceEnum.MOBILE;

    // Functions
    const generateID = (str: string) => {
        return str
            .toLowerCase()
            .replace(/[^\w\s-]/g, '')
            .replace(/\s+/g, '-')
            .replace(/--+/g, '-')
            .trim();
    };

    // Effects
    React.useImperativeHandle(ref, () => ({
        closePopUp(_open: boolean) {
            setOpen(_open);
        },
    }));

    // Component
    const ChildrenPopUp = () => (
        <Stack spacing={2}>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                borderBottom={`1px solid ${palette.primary}`}
                pb={2}>
                <Typography
                    flexGrow={1}
                    textAlign='center'
                    variant='t3_bold'
                    pl={{
                        xs: 0,
                        md: 5,
                    }}
                    color={palette.primary}>
                    {title}
                </Typography>
                {!isMobile && (
                    <IconButton
                        id={`FRONT_CONTROL-${buttonText ? generateID(buttonText) + '-' : ''}pop-up-close-button`}
                        size='small'
                        color='primary'
                        sx={iconMenuStyle}
                        onClick={() => setOpen(false)}>
                        <Box
                            fontSize={30}
                            component='span'
                            className='icon-close'></Box>
                    </IconButton>
                )}
            </Stack>
            <Box flexGrow={1}>{body}</Box>
            {!!buttonText && (
                <Button
                    id={`FRONT_CONTROL-${generateID(buttonText)}-button`}
                    onClick={() => {
                        buttonAction?.();
                    }}
                    variant='contained'>
                    {buttonText}
                </Button>
            )}
        </Stack>
    );

    return isMobile ? (
        <Drawer
            anchor='bottom'
            open={open}
            PaperProps={{
                sx: { width: '100%', p: 2, borderRadius: '12px 12px 0 0 ' },
            }}
            onClose={() => setOpen(false)}>
            <ChildrenPopUp />
        </Drawer>
    ) : (
        <Dialog
            fullScreen={isMobile}
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                ...dialogStyles,
                '& >div >.MuiPaper-root': {
                    width: '454px',
                },
            }}
            PaperProps={{
                sx: paperPropsStyles,
            }}>
            <ChildrenPopUp />
        </Dialog>
    );
});
PopUpTourWrapper.displayName = 'PopUpTourWrapper';
