import React from 'react';
import { Stack, Typography } from '@mui/material';
import { ImageTitleSlideProps } from './image-title-slider.types';
import Image from '@travel-platform/commons/src/components/common/next-image';

export const ImageTitleSlide: React.FC<ImageTitleSlideProps> = props => {
    // Destructure Props
    const { title, description, imageUrl, size } = props;
    // Constant
    const isLg = size === 'lg';
    return (
        <Stack
            direction='column'
            alignItems='center'
            spacing={{ xs: 1, md: 3 }}
            sx={{
                img: {
                    width: 1,
                    height: 'auto',
                    maxHeight: isLg ? 160 : 113,
                    borderRadius: {
                        xs: '5px',
                        md: '10px',
                    },
                },
            }}>
            <Image
                width={isLg ? 460 : 264}
                height={isLg ? 160 : 113}
                src={imageUrl}
                alt={title}
                title={title}
                style={{
                    objectFit: 'cover',
                }}
            />
            <Stack textAlign={'left'}>
                <Typography
                    variant='b1_bold'
                    sx={{
                        WebkitLineClamp: 1,
                    }}
                    className='verticalEllipsis'>
                    {title}
                </Typography>
                <Typography
                    sx={{
                        typography: {
                            xs: 'c1_regular',
                            md: 'b2_regular',
                        },
                        WebkitLineClamp: isLg ? 5 : 4,
                    }}
                    className='verticalEllipsis'>
                    {description}
                </Typography>
            </Stack>
        </Stack>
    );
};
