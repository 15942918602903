import { Box, Typography } from '@mui/material';
import styles from './tourist-magazine.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { ButtonModified } from '../../../common/button-modified/button-modified';
import SmallMagazine from './components/small-magazine/small-magazine';
import useDevice, { DeviceEnum } from '../../../common/hook/use-device';
import { MagazineType, TouristMagazineProps } from './tourist-magazine.types';
import BlogIcon from './images/blogIcon';
import React from 'react';

// const data: MagazineType[] = [
//     {
//         image: magazine1,
//         title: 'أفضل 10 أمکنة طبیعیة ینصح الذهاب إلیها في ترکیا',
//         desc: 'تعرّف علی أجمل وأشهر الوجهات الطبیعیة في ترکیا التي ینصح زیارتها حین الذهاب إلی ترکیا.',
//         link: 'https://safrat.me/blog/top-10-natural-places-to-visit-in-turkey/',
//     },
//     {
//         image: magazine2,
//         title: 'تعرف على أهمّ 10 أمكنة تاريخية للسياحة في قطر',
//         desc: 'إذا کنت من محبّي التراث القطري والأمکنة التاریخیّة في قطر، فهذا المقال سیدلّك على أفضل الوجهات التاريخية لقطر.',
//         link: 'https://safrat.me/blog/learn-about-the-10-best-historical-places-for-tourism-in-qatar/',
//     },
//     {
//         image: magazine3,
//         title: 'تعرّف على أفضل 10 وجهات طبيعية للسياحة في الإمارات.',
//         desc: 'قم بالتعرّف علی أفضل 10 وجهات طبیعیّة في الإمارات من خلال قراءة هذا المقال.',
//         link: 'https://safrat.me/blog/the-top-ten-natural-attractions-of-the-uae/',
//     },
//     {
//         image: magazine4,
//         title: 'تعرف علی أهم عشر وجهة للسیاحة التاریخیّة في إیران',
//         desc: 'هذا المقال یعرفك على أهم 10 أمكنة تاريخية مذهلة في إيران من تخت جمشيد إلى بيستون..',
//         link: 'https://safrat.me/blog/learn-about-the-top-10-destinations-for-historical-tourism-in-iran/',
//     },
// ];
const TouristMagazine = ({ posts }: TouristMagazineProps) => {
    // Use Hooks
    const isDesktop = useDevice() === DeviceEnum.DESKTOP;

    // Constant
    const settings = {
        className: 'center',
        centerMode: true,
        infinite: true,
        centerPadding: '24px',
        slidesToShow: 1,
        speed: 500,
        rtl: true,
    };
    const generatePosts: MagazineType[] = React.useMemo(
        () =>
            posts.map(({ title, link, excerpt, _embedded }) => {
                const {
                    alt_text,
                    media_details: { width, height, s3 },
                    source_url,
                } = _embedded['wp:featuredmedia'][0];
                return {
                    title: title.rendered,
                    desc: excerpt.rendered,
                    image: s3?.url || source_url || '',
                    link,
                    width,
                    height,
                    alt_text,
                };
            }),
        [posts]
    );

    return (
        <Box className={styles.container}>
            <Box className={styles.titleAndIcon}>
                <BlogIcon />
                <Typography className={styles.title}>المجلة السياحية</Typography>
            </Box>
            <Box className={styles.magazineFrame}>
                <Box className={styles.bigMagazineFrame}>
                    <Box className={styles.bigMagazine}>
                        <Box className={styles.imageBox}>
                            <Box
                                className={styles.image}
                                style={{ backgroundImage: `url(${generatePosts[0].image})` }}></Box>
                        </Box>
                        {generatePosts[0].date && <Typography className={styles.date}>{generatePosts[0].date}</Typography>}
                        <Typography className={styles.title}>{generatePosts[0].title}</Typography>
                        {/* <Box className={styles.text}>{generatePosts[0].desc}</Box> */}
                        <Box
                            className={styles.text}
                            dangerouslySetInnerHTML={{ __html: generatePosts[0].desc }}></Box>
                        <Box
                            display={'flex'}
                            justifyContent={'end'}>
                            <a
                                target='_blank'
                                href={generatePosts[0].link}
                                rel='noreferrer'>
                                <ButtonModified
                                    fullWidth
                                    className={styles.btn}
                                    sx={{ borderRadius: '8px', fontSize: '12px' }}
                                    size='medium'
                                    variant='outlined'
                                    endIcon={<span className={'icon-chevron-light-left ' + styles.iconbtn}></span>}>
                                    قراءة المزيد
                                </ButtonModified>
                            </a>
                        </Box>
                    </Box>
                </Box>
                {!isDesktop ? (
                    <Box className={styles.smallMagazineFrame}>
                        <Slider {...settings}>
                            {generatePosts.map(
                                (magazine, index) =>
                                    index !== 0 && (
                                        <SmallMagazine
                                            data={magazine}
                                            key={index}
                                        />
                                    )
                            )}
                        </Slider>
                    </Box>
                ) : (
                    <Box className={styles.smallMagazineFrame}>
                        {generatePosts.map(
                            (magazine, index) =>
                                index !== 0 && (
                                    <SmallMagazine
                                        data={magazine}
                                        key={index}
                                    />
                                )
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default TouristMagazine;
