import React from 'react';
import { Stack, Typography } from '@mui/material';
import { HtmlRender } from '@travel-platform/commons/src';

type TitleDescriptionProps = {
    title: string;
    description: string;
};

const TitleDescription: React.FC<TitleDescriptionProps> = ({ title, description }: TitleDescriptionProps) => {
    return (
        <Stack>
            <Typography
                component='h2'
                sx={{
                    typography: {
                        xs: 't2_bold',
                        md: 't1_bold',
                    },
                }}>
                {title}
            </Typography>
            <HtmlRender
                sx={{
                    typography: {
                        xs: 'b1_regular',
                        md: 't3_regular',
                    },
                }}>
                {description}
            </HtmlRender>
        </Stack>
    );
};
export default TitleDescription;
