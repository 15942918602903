import { Box, Grid, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { CabinClass, EnumFlight, LocationType } from '../../../../services/flights/flight.types';
import { ButtonModified } from '../../../common/button-modified/button-modified';
import MostBookedFlightsCard from './components/most-booked-flights-card/most-booked-flights-card';
import styles from './most-booked-flights-section.module.scss';
import staticData from '../../../landing/components/origin-destination/static-options.json';
import { toDateObject } from '../../../common/date-picker/functions';
import useDevice, { DeviceEnum } from '../../../common/hook/use-device';
import { MostBookedFlightsSectionProps } from './most-booked-flights-section.types';
import { FlightSearchData } from '../../../flights/search-fields-flights/search-fields-flights.types';
import { generateSearchParamsFlight } from '../../../flights/search-fields-flights/functions';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';
import { isIOS } from 'react-device-detect';
import { PROFILE_PROJECT, ProfileProjectEnum } from '@travel-platform/commons/src/components/common/constant/constant';
const MostBookedFlightsSection = (props: MostBookedFlightsSectionProps) => {
    const device = useDevice();
    const router = useRouter();
    const languageJson = useLanguage();
    const [MostBookedFlights, setMostBookedFlights] = useState<
        {
            origin: string;
            destination: string;
        }[]
    >([
        {
            origin: 'BGW',
            destination: 'DXB',
        },
        {
            origin: 'BGW',
            destination: 'IST',
        },
        {
            origin: 'BGW',
            destination: 'BEY',
        },
        {
            origin: 'BGW',
            destination: 'THR',
        },
        {
            origin: 'NJF',
            destination: 'MHD',
        },
        {
            origin: 'DXB',
            destination: 'IST',
        },
        {
            origin: 'NJF',
            destination: 'THR',
        },
        {
            origin: 'EBL',
            destination: 'DXB',
        },
    ]);

    const isVisitOurIran = PROFILE_PROJECT === ProfileProjectEnum.VISITOURIRAN;

    useEffect(() => {
        if (isIOS && !isVisitOurIran) {
            setMostBookedFlights(MostBookedFlights.filter(flight => flight.destination !== 'THR'));
        }
    }, []);

    const [selectedDay, setSelectedDay] = useState<{
        label: string;
        value: Date;
        key: number;
    }>({
        label: new Date(new Date().setDate(new Date().getDate() + 1)).toLocaleString(router.locale, { weekday: 'long' }),
        value: new Date(new Date().setDate(new Date().getDate() + 1)),
        key: 1,
    });
    const days = [
        {
            label: new Date(new Date().setDate(new Date().getDate() + 1)).toLocaleString(router.locale, { weekday: 'long' }),
            value: new Date(new Date().setDate(new Date().getDate() + 1)),
            key: 1,
        },
        {
            label: new Date(new Date().setDate(new Date().getDate() + 2)).toLocaleString(router.locale, { weekday: 'long' }),
            value: new Date(new Date().setDate(new Date().getDate() + 2)),
            key: 2,
        },
        {
            label: new Date(new Date().setDate(new Date().getDate() + 3)).toLocaleString(router.locale, { weekday: 'long' }),
            value: new Date(new Date().setDate(new Date().getDate() + 3)),
            key: 3,
        },
        {
            label: new Date(new Date().setDate(new Date().getDate() + 4)).toLocaleString(router.locale, { weekday: 'long' }),
            value: new Date(new Date().setDate(new Date().getDate() + 4)),
            key: 4,
        },
    ];
    const search = (dataCity: { origin: string; destination: string }) => {
        const origin = staticData.find(data => data.cities[0].cityCode === dataCity.origin);
        const destination = staticData.find(data => data.cities[0].cityCode === dataCity.destination);
        const startDate = toDateObject(selectedDay.value);
        if (origin && destination) {
            const originData = {
                label: origin.cities[0].cityNames.find(city => city.language === router.locale)?.value || '',
                code: origin.cities[0].cityCode,
                type: LocationType.CITY,
                country: origin.countryNames.find(country => country.language === router.locale)?.value || '',
            };
            const destinationData = {
                label: destination.cities[0].cityNames.find(city => city.language === router.locale)?.value || '',
                code: destination.cities[0].cityCode,
                type: LocationType.CITY,
                country: destination.countryNames.find(country => country.language === router.locale)?.value || '',
            };
            const searchData: FlightSearchData = {
                tripType: EnumFlight.ONEWAY,
                flightClass: CabinClass.ECONOMY,
                originDestination: { origin: originData, destination: destinationData },
                passengers: {
                    adult: 1,
                    child: 0,
                    infant: 0,
                },
                rangeDate: {
                    start: { year: startDate.year, month: startDate.month, day: startDate.day },
                },
            };
            const params = generateSearchParamsFlight(searchData);
            router.push(`/flights/searchresults?${params}`, '', {});
        }
    };
    const isMobile = device === DeviceEnum.MOBILE;
    return (
        <Typography
            component='div'
            className={styles.container}>
            <Box className={styles.titleAndWeekbtn}>
                <Box className={styles.title}>{languageJson.common.most_visited}</Box>
                <Box className={styles.weekbtnField}>
                    {days.map((day, index) => (
                        <ButtonModified
                            key={index}
                            onClick={() => setSelectedDay(day)}
                            size='small'
                            variant={day.key === selectedDay.key ? 'contained' : 'text'}>
                            {day.label}
                        </ButtonModified>
                    ))}
                </Box>
            </Box>
            <Grid
                container
                spacing={isMobile ? 2 : 3}
                className={styles.MostBookedFlightsField}>
                {MostBookedFlights.map((MostBookedFlights, index) => (
                    <Grid
                        item
                        md={3}
                        xs={6}
                        key={index}>
                        <Box
                            onClick={() => search(MostBookedFlights)}
                            className={styles.linkMostBooked}
                            key={index}>
                            <MostBookedFlightsCard
                                origin={staticData.find(data => data.cities[0].cityCode === MostBookedFlights.origin)?.cities[0].cityNames.find(city => city.language === router.locale)?.value || MostBookedFlights.origin}
                                destination={staticData.find(data => data.cities[0].cityCode === MostBookedFlights.destination)?.cities[0].cityNames.find(city => city.language === router.locale)?.value || MostBookedFlights.destination}
                            />
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Typography>
    );
};

export default MostBookedFlightsSection;
