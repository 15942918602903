import React from 'react';
import { Stack, Typography } from '@mui/material';
import Image from '@travel-platform/commons/src/components/common/next-image';

export type TourStyleIntroductionCardProps = {
    title: string;
    description: string;
    imageUrl: string;
};

export const TourStyleIntroductionCard: React.FC<TourStyleIntroductionCardProps> = props => {
    // Destructure Props
    const { title, description, imageUrl } = props;

    return (
        <Stack
            direction='row'
            alignItems='center'
            spacing={3}>
            <Image
                width={{
                    xs: 160,
                    md: 256,
                }}
                height={{
                    xs: 112,
                    md: 180,
                }}
                src={imageUrl}
                alt={title}
                title={title}
                style={{
                    borderRadius: '8px',
                }}
            />
            <Stack>
                <Typography
                    variant='b1_bold'
                    className='verticalEllipsis'
                    sx={{
                        WebkitLineClamp: 2,
                    }}>
                    {title}
                </Typography>
                <Typography
                    sx={{
                        typography: {
                            xs: 'c1_regular',
                            md: 'b2_regular',
                        },
                        WebkitLineClamp: 4,
                    }}
                    className='verticalEllipsis'>
                    {description}
                </Typography>
            </Stack>
        </Stack>
    );
};
