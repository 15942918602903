import React from 'react';
import Link from 'next/link';
import type { TourCardProps } from './tour-card.types';
import { Box, Rating, Stack, Typography } from '@mui/material';
import palette from '../../common/style/color.module.scss';
import { SliderFullPaginationPropsImage } from '@travel-platform/commons/src/components/common/slider/slider.types';
import useDevice, { DeviceEnum } from '@travel-platform/commons/src/hook/use-device';
import { Badge } from '../badge';
import Image from '@travel-platform/commons/src/components/common/next-image';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';
import { useRouter } from 'next/router';
import { useExchangeCurrency } from '../hook/use-exchange-currency';
import { generateSingleTourUrl } from '@travel-platform/commons/src';

export const TourCard: React.FC<TourCardProps> = ({ id, slug, title, from_price, description, media, rates, styles, date, tour_leader, thumbnail }) => {
    // Use Hooks
    const isMobile = useDevice() === DeviceEnum.MOBILE;
    const router = useRouter();
    const { exchangePrice, currency } = useExchangeCurrency();
    const { direction, common } = useLanguage();

    // Functions
    function formatDate(date: string) {
        const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
        const newDate = new Date(date);
        const fullDate: string = newDate.toLocaleDateString(router.locale, options);
        const monthName: string = newDate.toLocaleString(router.locale, { month: 'long' });
        const day: number = newDate.getDate();
        const month: number = newDate.getMonth() + 1; // Month is zero-based, so add 1
        const year: number = newDate.getFullYear();
        return { fullDate, day, month, year, monthName };
    }
    const generateSliderImage: SliderFullPaginationPropsImage[] | undefined = React.useMemo(() => {
        return media?.slice(0, 1).map(({ original_url, original_name, property }) => {
            return {
                src: original_url,
                alt: property?.alt || '',
                title: original_name,
                url: original_url,
            };
        });
    }, [media]);

    const convertDate = !!date ? formatDate?.(date) : undefined;

    return (
        <Link
            href={slug ? generateSingleTourUrl(id, slug, false) : '#'}
            style={{
                color: 'black',
            }}>
            <Stack
                justifyContent='space-between'
                height={1}
                spacing={1}>
                <Stack
                    justifyContent='space-between'
                    spacing={1}>
                    <Box
                        height={{
                            xs: '168px',
                            md: '223px',
                        }}>
                        {!!thumbnail && (
                            <Image
                                width={800}
                                height={450}
                                src={thumbnail?.original_url || null}
                                alt={thumbnail?.property?.alt!}
                                title={thumbnail?.original_name}
                                style={{
                                    borderRadius: '5px',
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        )}
                    </Box>

                    <Typography
                        className='verticalEllipsis'
                        sx={{
                            typography: {
                                xs: 'b1_bold',
                                md: 't3_bold',
                            },
                            WebkitLineClamp: 2,
                            textAlign: 'left',
                        }}>
                        {title}
                    </Typography>
                </Stack>
                <Stack
                    justifyContent='space-between'
                    alignItems={direction === 'ltr' ? 'flex-start' : 'flex-end'}
                    spacing={1}>
                    {!!rates && (
                        <Stack
                            direction={direction === 'ltr' ? 'row' : 'row-reverse'}
                            spacing={1}>
                            <Rating
                                readOnly
                                value={rates.average}
                                precision={1}
                                sx={{
                                    color: palette.warning,
                                }}
                            />
                            <Typography
                                sx={{
                                    typography: {
                                        xs: 'c1_regular',
                                        md: 'b1_regular',
                                    },
                                }}>
                                {`(${rates.count})`}
                            </Typography>
                        </Stack>
                    )}

                    {!!styles && (
                        <Stack direction='row'>
                            <Badge
                                size={isMobile ? 'sm' : 'lg'}
                                text={styles.text}
                                color={styles.color}
                            />
                        </Stack>
                    )}
                    {!!tour_leader && (
                        <Stack direction='row'>
                            <Stack
                                direction={direction === 'ltr' ? 'row' : 'row-reverse'}
                                alignItems='center'
                                justifyContent={{
                                    xs: 'flex-start',
                                    md: 'center',
                                }}
                                gap={1}
                                sx={{
                                    img: {
                                        width: 1,
                                        height: 1,
                                        maxWidth: {
                                            xs: '35px',
                                            md: '50px',
                                        },
                                        maxHeight: {
                                            xs: '35px',
                                            md: '50px',
                                        },
                                        borderRadius: '999px',
                                    },
                                }}>
                                <Image
                                    width={96}
                                    height={96}
                                    title={tour_leader.thumbnail?.name || ''}
                                    alt={tour_leader.thumbnail?.name || ''}
                                    src={tour_leader.thumbnail.url}
                                />
                                <Typography variant='b2_bold'>{tour_leader.name}</Typography>
                            </Stack>
                        </Stack>
                    )}

                    <Stack
                        spacing={1}
                        flexGrow={1}>
                        {!!convertDate && (
                            <Typography
                                sx={{
                                    typography: {
                                        xs: 'b2_bold',
                                        md: 't3_bold',
                                    },
                                    color: palette.new_black,
                                    textAlign: 'left',
                                }}>
                                <Stack
                                    direction={direction === 'ltr' ? 'row' : 'row-reverse'}
                                    gap={1}>
                                    <span>{convertDate.day}</span>
                                    <span>{convertDate.monthName}</span>
                                    <span>{convertDate.year}</span>
                                </Stack>
                            </Typography>
                        )}
                        {!!from_price && (
                            <Stack
                                alignItems={direction === 'ltr' ? 'flex-start' : 'flex-end'}
                                direction={direction === 'ltr' ? 'row' : 'row-reverse'}
                                gap={1}>
                                <Typography
                                    sx={{
                                        typography: {
                                            xs: 'b2_bold',
                                            md: 't3_bold',
                                        },
                                        color: palette.new_black,
                                        textAlign: 'left',
                                    }}>
                                    {`${common.from}`}
                                </Typography>
                                <Typography
                                    sx={{
                                        typography: {
                                            xs: 'b2_bold',
                                            md: 't3_bold',
                                        },
                                        color: palette.new_black,
                                        textAlign: 'left',
                                    }}>
                                    {`${exchangePrice(from_price, 'USD')} ${currency}`}
                                </Typography>
                            </Stack>
                        )}
                        <Typography
                            className='verticalEllipsis'
                            sx={{
                                typography: {
                                    xs: 'c1_regular',
                                    md: 't3_regular',
                                },
                                WebkitLineClamp: 2,
                                textAlign: 'left',
                            }}>
                            {description}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Link>
    );
};
