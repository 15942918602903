import { Box, Typography } from '@mui/material';
import { ButtonModified } from '../../../../../common/button-modified/button-modified';
import styles from './small-magazine.module.scss';
import { SmallMagazineProps } from './small-magazine.types';

export default function SmallMagazine(props: SmallMagazineProps) {
    const { data } = props;
    return (
        <Box className={styles.smallMagazine}>
            <Box className={styles.imageBox}>
                <Box
                    className={styles.image}
                    style={{ backgroundImage: `url(${data.image})` }}></Box>
            </Box>
            <Box className={styles.dateTitleBtn}>
                <Box className={styles.dateAndTitle}>
                    {data.date && <Typography className={styles.date}>{data.date}</Typography>}
                    <Typography className={styles.title}>{data.title}</Typography>
                </Box>
                <Box
                    display={'flex'}
                    justifyContent={'end'}>
                    <a
                        target='_blank'
                        href={data.link}
                        rel='noreferrer'>
                        <ButtonModified
                            fullWidth
                            sx={{ borderRadius: '8px', fontSize: '10px' }}
                            className={styles.btn}
                            size='small'
                            variant='outlined'
                            endIcon={<span className='icon-chevron-light-left '></span>}>
                            قراءة المزيد
                        </ButtonModified>
                    </a>
                </Box>
            </Box>
        </Box>
    );
}
