import { Box, Typography } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useAirportTranslate from '../hook/use-airport-translate';
import styles from './recent-search-card.module.scss';
import { RecentSearchCardProps } from './recent-search-card.types';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';

export default function RecentSearchCard(props: RecentSearchCardProps) {
    const { data, url } = props.data;
    const airportTranslate = useAirportTranslate();
    const languageJson = useLanguage();
    const router = useRouter();
    return (
        <Typography
            component={'div'}
            className={styles.recentSearchCard}>
            <Link href={url}>
                <Box className={styles.content}>
                    <Box className={styles.origin_destination_icon}>
                        <Box className={styles.origin}>{data.originDestination.origin.label}</Box>
                        <Box className={styles.icon_text}>
                            <Box className={styles.text}>{languageJson.state.trip_type_list[data.tripType.toLocaleLowerCase() as keyof typeof languageJson.state.trip_type_list]}</Box>
                            <Box className={styles.icon}>
                                <span className={router.locale === 'ar' ? 'icon-arrow-left' : 'icon-arrow-right'}></span>
                            </Box>
                        </Box>
                        <Box className={styles.destination}>{data.originDestination.destination.label}</Box>
                    </Box>
                    <Box className={styles.date_type}>
                        <Box className={styles.date}>
                            <Box className={styles.icon}>
                                <span className='icon-calendar'></span>
                            </Box>
                            <Box className={styles.dateText}>{`${data.rangeDate.start.month} / ${data.rangeDate.start.day}`}</Box>
                        </Box>
                        <Box className={styles.classType}>{languageJson.state.class_flight_list[data.flightClass.toLocaleLowerCase() as keyof typeof languageJson.state.class_flight_list]}</Box>
                    </Box>
                </Box>
            </Link>
        </Typography>
    );
}
