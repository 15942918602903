import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import Slider from 'react-slick';
import { ImageTitleSlideProps, ImageTitleSliderProps } from './image-title-slider.types';
import { useRouter } from 'next/router';
import { ImageTitleSlide } from './image-title-slide';
import styles from '../most-visited-city-section/most-visited-city-section.module.scss';

export const ImageTitleSlider: React.FC<ImageTitleSliderProps> = ({ title, items }) => {
    // Use Hooks
    const router = useRouter();
    console.log(items);
    // Slider Setting
    const settings = {
        slidesToShow: 2,
        infinite: true,
        speed: 500,
        autoplaySpeed: 5000,
        rtl: router.locale === 'ar',
        autoplay: true,
        centered: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2.5,
                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1.5,
                },
            },
        ],
    };
    return (
        <Stack
            spacing={{
                xs: 1,
                md: 2,
            }}>
            <Typography
                component='h2'
                sx={{
                    typography: {
                        xs: 't3_bold',
                        md: 't2_bold',
                    },
                }}>
                {title}
            </Typography>
            <Box className={styles.mostVisitedCitySection}>
                <Slider
                    {...settings}
                    className={styles.slider + ' slider-tops'}>
                    {items.map((item: ImageTitleSlideProps, index) => (
                        <ImageTitleSlide
                            key={index}
                            {...item}
                        />
                    ))}
                </Slider>
            </Box>
        </Stack>
    );
};
