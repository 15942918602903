const Chart = () => {
    return (
        <svg
            width='72'
            height='73'
            viewBox='0 0 72 73'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <g opacity='0.96'>
                <path
                    d='M62.5 63.3105H12.5C11.475 63.3105 10.625 62.4605 10.625 61.4355C10.625 60.4105 11.475 59.5605 12.5 59.5605H62.5C63.525 59.5605 64.375 60.4105 64.375 61.4355C64.375 62.4605 63.525 63.3105 62.5 63.3105Z'
                    fill='#E8C7FB'
                />
                <path
                    d='M31.875 18.3101V63.3101H43.125V18.3101C43.125 15.5601 42 13.3101 38.625 13.3101H36.375C33 13.3101 31.875 15.5601 31.875 18.3101Z'
                    fill='#E8C7FB'
                />
                <path
                    d='M15 33.3101V63.3101H25V33.3101C25 30.5601 24 28.3101 21 28.3101H19C16 28.3101 15 30.5601 15 33.3101Z'
                    fill='#E8C7FB'
                />
                <path
                    d='M50 45.8105V63.3106H60V45.8105C60 43.0605 59 40.8105 56 40.8105H54C51 40.8105 50 43.0605 50 45.8105Z'
                    fill='#E8C7FB'
                />
                <path
                    d='M59.5 60.3105H9.5C8.475 60.3105 7.625 59.4605 7.625 58.4355C7.625 57.4105 8.475 56.5605 9.5 56.5605H59.5C60.525 56.5605 61.375 57.4105 61.375 58.4355C61.375 59.4605 60.525 60.3105 59.5 60.3105Z'
                    fill='#55286F'
                />
                <path
                    d='M28.875 15.3101V60.3101H40.125V15.3101C40.125 12.5601 39 10.3101 35.625 10.3101H33.375C30 10.3101 28.875 12.5601 28.875 15.3101Z'
                    fill='#55286F'
                />
                <path
                    d='M12 30.3101V60.3101H22V30.3101C22 27.5601 21 25.3101 18 25.3101H16C13 25.3101 12 27.5601 12 30.3101Z'
                    fill='#55286F'
                />
                <path
                    d='M47 42.8105V60.3106H57V42.8105C57 40.0605 56 37.8105 53 37.8105H51C48 37.8105 47 40.0605 47 42.8105Z'
                    fill='#55286F'
                />
            </g>
        </svg>
    );
};
export default Chart;
