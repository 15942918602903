import React, { useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import Link from 'next/link';
import Image from 'next/image';

import useDevice, { DeviceEnum } from 'components/common/hook/use-device';
import { CmsLandingComponentsMarketingBanner, CmsLandingComponentsMarketingBannerItem } from '@travel-platform/commons/src/types';
import { isIOS } from 'react-device-detect';
import { PROFILE_PROJECT, ProfileProjectEnum } from '@travel-platform/commons/src/components/common/constant/constant';

const isVisitOurIran = PROFILE_PROJECT === ProfileProjectEnum.VISITOURIRAN;

export const MarketingBanner: React.FC<CmsLandingComponentsMarketingBanner> = ({ items }) => {
    const filteredDataOnIran = useMemo(() => {
        return isIOS && !isVisitOurIran ? items.filter(item => !item.url.includes('iran')) : items;
    }, [items]);

    const isMobile = useDevice() === DeviceEnum.MOBILE;
    return (
        <Box>
            <Grid
                container
                spacing={3}>
                {isMobile ? (
                    <React.Fragment>
                        <Grid
                            item
                            xs={12}>
                            <Grid
                                container
                                spacing={2}>
                                {!!filteredDataOnIran?.[0] && (
                                    <Grid
                                        item
                                        xs={filteredDataOnIran.length > 1 ? 6 : 12}>
                                        <MarketingBannerItem {...filteredDataOnIran?.[0]} />
                                    </Grid>
                                )}
                                {!!filteredDataOnIran?.[2] ? (
                                    <Grid
                                        item
                                        xs={6}>
                                        <MarketingBannerItem {...filteredDataOnIran?.[2]} />
                                    </Grid>
                                ) : (
                                    <>
                                        {filteredDataOnIran?.[1] && (
                                            <Grid
                                                item
                                                xs={6}>
                                                <MarketingBannerItem {...filteredDataOnIran?.[1]} />
                                            </Grid>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Grid>

                        {!!filteredDataOnIran?.[1] && filteredDataOnIran.length > 2 && (
                            <Grid
                                item
                                xs={12}>
                                <MarketingBannerItem {...filteredDataOnIran?.[1]} />
                            </Grid>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {!!filteredDataOnIran?.[0] && (
                            <Grid
                                item
                                xs={3}>
                                <MarketingBannerItem {...filteredDataOnIran?.[0]} />
                            </Grid>
                        )}
                        {!!filteredDataOnIran?.[1] && (
                            <Grid
                                item
                                xs={6}>
                                <MarketingBannerItem {...filteredDataOnIran?.[1]} />
                            </Grid>
                        )}
                        {!!filteredDataOnIran?.[2] && (
                            <Grid
                                item
                                xs={3}>
                                <MarketingBannerItem {...filteredDataOnIran?.[2]} />
                            </Grid>
                        )}
                    </React.Fragment>
                )}
            </Grid>
        </Box>
    );
};

export const MarketingBannerItem: React.FC<CmsLandingComponentsMarketingBannerItem> = ({ imageUrl, url }) => {
    return (
        <Box
            sx={{
                img: {
                    width: 1,
                    maxHeight: {
                        xs: '160px',
                        md: '252px',
                    },
                    borderRadius: 2,
                },
            }}>
            <Link href={url || ''}>
                <Image
                    width={800}
                    height={450}
                    src={imageUrl}
                    alt=''
                />
            </Link>
        </Box>
    );
};
