import { Box, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { authState } from '@travel-platform/commons/src/redux/auth-slice';
import { useAppSelector } from '../../../../../../redux/hooks';
import styles from './navigation-item.module.scss';
import { NavigationItemProps } from './navigation-item.types';

export default function NavigationItem(props: NavigationItemProps) {
    const { url, text, icon, iconActive, needsLogin, showLoginModal } = props;
    const router = useRouter();
    const authState: authState = useAppSelector(state => state.auth);
    const { auth } = authState;
    const navigate = () => {
        if (needsLogin && !auth?.token) {
            showLoginModal?.();
        } else {
            window.location.href = url;
        }
    };
    const isUrlMatchToItem = router.pathname === url;
    return (
        <Typography
            component='div'
            onClick={navigate}
            className={styles.menuItem + ' ' + (isUrlMatchToItem && styles.active)}>
            <Box className={styles.icon}>{isUrlMatchToItem ? <span className={iconActive}></span> : <span className={icon}></span>}</Box>
            <Box className={styles.text}>{text}</Box>
        </Typography>
    );
}
