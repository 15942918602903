import { Box } from '@mui/material';
import { useRouter } from 'next/router';
import Slider from 'react-slick';
import styles from '../most-visited-city-section/most-visited-city-section.module.scss';
import React from 'react';
import { TourCard } from 'components/common/tour-card';
import { Departure } from '@travel-platform/commons/src/services/tours';

type DepartureTourProps = {
    departure: Departure[];
};
export const DepartureTour: React.FC<DepartureTourProps> = ({ departure }) => {
    // Use Hooks
    const router = useRouter();

    // Slider Setting
    const settings = {
        slidesToShow: 4,
        // infinite: true,
        speed: 500,
        autoplaySpeed: 5000,
        rtl: router.locale === 'ar',
        autoplay: true,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2.5,
                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1.5,
                },
            },
        ],
    };

    return (
        <Box className={styles.mostVisitedCitySection}>
            <Slider
                {...settings}
                className={styles.slider + ' sliderLanding'}>
                {departure.map((item: Departure, index) => (
                    <TourCard
                        key={index}
                        id={item?.tour?.id}
                        slug={item?.tour?.slug || null}
                        title={item.title}
                        description={item.description}
                        from_price={item.from_price}
                        date={item.start_date}
                        tour_leader={item.tour_leader}
                        media={!!item?.tour?.thumbnail ? [{ ...item.tour.thumbnail }] : undefined}
                    />
                ))}
            </Slider>
        </Box>
    );
};
