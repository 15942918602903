import React from 'react';
import { ImageTitleSlideProps, ImageTitleSlider } from './image-title-slider';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';
import useDevice, { DeviceEnum } from '@travel-platform/commons/src/hook/use-device';
import { CmsLandingComponentsTopActivity } from '@travel-platform/commons/src/types';

export const TourStyleContentTopActivities: React.FC<CmsLandingComponentsTopActivity> = ({ title, items }) => {
    // Use Hook
    const languageJson = useLanguage();
    const isMobile = useDevice() === DeviceEnum.MOBILE;

    // Functions
    const generateData: ImageTitleSlideProps[] = React.useMemo(() => {
        return (
            items?.map(({ title, description, imageUrl }): ImageTitleSlideProps => {
                return {
                    title,
                    description,
                    imageUrl,
                    size: isMobile ? 'sm' : 'lg',
                };
            }) || []
        );
    }, [items]);

    return (
        <ImageTitleSlider
            title={title}
            items={generateData}
        />
    );
};
