import React from 'react';
import { Typography, type SxProps, type Theme, Stack } from '@mui/material';

export type BadgeType = {
    text: string;
    icon?: string;
    color: string;
    size: 'lg' | 'sm';
    isOutline?: boolean;
};

export const Badge: React.FC<BadgeType> = ({ text, color, size = 'sm', isOutline = false }: BadgeType) => {
    // Constant
    const isSmall = size === 'sm';

    // Mui Style
    const badgeStyle: SxProps<Theme> = {
        py: isSmall ? '2px' : 0.5,
        px: 1,
        borderRadius: '9999px',
    };
    return (
        <Stack
            sx={badgeStyle}
            style={{
                ...(!isOutline
                    ? {
                          backgroundColor: color,
                      }
                    : {}),
                color: !isOutline ? 'white' : color,
                border: `1px solid ${color}`,
            }}
            direction='row'
            alignItems='center'
            spacing={isSmall ? 0.5 : 1}>
            <Typography
                pt={isSmall ? 0.3 : 0}
                variant={isSmall ? 'c1_bold' : 'b2_bold'}>
                {text}
            </Typography>
        </Stack>
    );
};
