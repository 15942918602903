import palette from 'components/common/style/color.module.scss';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { TravelServiceVariantsItems, CmsLandingComponentsTravelServicePlanItems, CmsLandingComponentsTravelServicePlan } from 'types';
import { useAppSelector } from 'redux/hooks';
import { updateServiceCartItem, addServiceCart, EmptyServiceCartObject } from '@travel-platform/commons/src/redux/cart-slice';

function LandingPageTravelServicePlanItem(props: { travelServiceVariant: TravelServiceVariantsItems; planInfo: CmsLandingComponentsTravelServicePlanItems }) {
    // Destructure Props
    const { travelServiceVariant, planInfo } = props;

    // Use Hooks
    const dispatch = useDispatch();

    const { ServiceCart } = useAppSelector(state => state.cart);
    //Functions
    const updateData = () => {
        if (!travelServiceVariant) return;

        const findDuplicateVariant = ServiceCart.find(item => item.travelServiceId === travelServiceVariant.travelServiceId && item.id === travelServiceVariant.id);

        const findEmptyVariant = ServiceCart.find(item => item.travelServiceId === travelServiceVariant.travelServiceId && item.id === 0);

        if (findDuplicateVariant) {
            dispatch(
                updateServiceCartItem({
                    ...findDuplicateVariant,
                    ...travelServiceVariant,
                    quantity: findDuplicateVariant.quantity + 1,
                })
            );
        } else if (findEmptyVariant) {
            dispatch(updateServiceCartItem({ ...findEmptyVariant, ...travelServiceVariant, quantity: 1 }));
        } else {
            dispatch(
                addServiceCart({
                    ...EmptyServiceCartObject,
                    ...travelServiceVariant,
                    quantity: 1,
                    fakeId: uuidv4(),
                })
            );
        }
    };

    return (
        <Stack
            alignItems='center'
            border={`1px solid ${palette.primary}`}
            borderRadius={3}
            spacing={2}>
            <Stack
                width='100%'
                alignItems='center'>
                <Typography
                    width='100%'
                    py={1.5}
                    borderBottom={`1px solid ${palette.primary}`}
                    p={2}
                    textAlign='center'
                    sx={{
                        typography: {
                            xs: 'b2_bold',
                            md: 't3_bold',
                        },
                    }}>
                    {planInfo.title}
                </Typography>
                {planInfo.description && (
                    <Typography
                        p={2}
                        pb={0}
                        textAlign='center'
                        component='span'
                        sx={{
                            typography: {
                                xs: 'c1_regular',
                                md: 'b1_regular',
                            },
                            '& div p': {
                                margin: '0px !important',
                            },
                        }}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: planInfo.description,
                            }}
                        />
                    </Typography>
                )}
            </Stack>
            <Box
                px={2}
                pb={2}>
                <Button
                    color='primary'
                    variant='contained'
                    onClick={updateData}
                    sx={{
                        typography: {
                            xs: 'c2_bold',
                            md: 'b2_bold',
                        },
                    }}>
                    {planInfo.button_text}
                </Button>
            </Box>
        </Stack>
    );
}

function LandingPageTravelServicePlan(props: CmsLandingComponentsTravelServicePlan) {
    // Destructure Props
    const { title, landing_travel_service_plan_items, services, landingTravelServiceId } = props;

    // Use Hooks
    const router = useRouter();

    // Find Service
    const serviceSelected = services.find(({ id }) => id === landingTravelServiceId);

    if (!serviceSelected) return <React.Fragment />;

    return (
        <Stack spacing={2}>
            <Stack spacing={2}>
                <Typography
                    component='h2'
                    sx={{
                        typography: {
                            xs: 'b1_bold',
                            md: 't1_bold',
                        },
                    }}>
                    {title}
                </Typography>
            </Stack>
            <Box>
                <Grid
                    container
                    spacing={{
                        xs: 2,
                        md: 3,
                    }}>
                    {landing_travel_service_plan_items.map((item, index) => {
                        if (!serviceSelected?.travelServiceVariants) return <React.Fragment key={index} />;

                        const findPlanVariant = serviceSelected?.travelServiceVariants.find(({ id }) => id === item.travel_service_variant_id);

                        if (!findPlanVariant) return <React.Fragment key={index} />;

                        return (
                            <Grid
                                item
                                key={index}
                                xs={6}
                                md={3}>
                                <LandingPageTravelServicePlanItem
                                    planInfo={item}
                                    travelServiceVariant={findPlanVariant}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </Stack>
    );
}

export default LandingPageTravelServicePlan;
