import { CraftJourneyForm, HorizontalCard } from '@travel-platform/commons/src';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';
import { FormsRef } from '@travel-platform/commons/src/types';
import { PopUpTourActionProps, PopUpTourWrapper } from 'components/tours/single-tour/popup';
import React from 'react';

const CraftTourCTA: React.FC = () => {
    const languageJson = useLanguage();
    // States
    const [popUpData, setPopUpData] = React.useState<PopUpTourActionProps>({
        title: '',
        body: <></>,
    });
    const popUpRef = React.useRef<FormsRef | any>();
    const submitForms = React.useRef<FormsRef | any>();

    const togglePopUp = () => {
        setPopUpData({
            title: languageJson.common.craft_my_journey,
            body: (
                <CraftJourneyForm
                    defaultInputType='mobile'
                    ref={submitForms}
                    inputType='both'
                    onClose={() => {
                        popUpRef?.current?.closePopUp(false);
                    }}
                />
            ),
            buttonText: languageJson.common.request_a_tour,
            buttonAction: () => {
                submitForms?.current?.onSubmit();
            },
        });
        popUpRef?.current?.closePopUp(true);
    };
    return (
        <>
            <PopUpTourWrapper
                ref={popUpRef}
                {...popUpData}
            />
            <HorizontalCard
                buttonAction={() => togglePopUp()}
                buttonText={languageJson.common.craft_your_journey.title}
                description={languageJson.common.craft_your_journey.desc}
                imageSrc='/Craft My tour.webp'
            />
        </>
    );
};
export default CraftTourCTA;
