import { Box } from '@mui/material';
import React from 'react';
import styles from './most-booked-flights-card.module.scss';
import { MostBookedFlightsCardProps } from './most-booked-flights-card.types';

const MostBookedFlightsCard = (props: MostBookedFlightsCardProps) => {
    const { origin, destination } = props;
    return (
        <Box className={styles.MostBookedFlightsCard}>
            <Box className={styles.originAirplaneDestination}>
                <Box className={styles.origin}>{origin}</Box>
                <Box className={styles.airplane}>
                    <Box className={'icon-airplane-fill-leftward ' + styles.airplaneIcon}></Box>
                    <Box className={styles.line}></Box>
                    <Box className={styles.circle}></Box>
                </Box>
                <Box className={styles.destination}>{destination}</Box>
            </Box>
            {/* <Box className={styles.startingPriceField}>
                <Box className={styles.startingPriceText}>
                    بدء السّعر من
                </Box>
                <Box className={styles.priceAndCurrency}>
                    <Box className={styles.price}>{priceDivider(props.price)}</Box>
                    <Box className={styles.currency}>{currency}</Box>
                </Box>

            </Box> */}
        </Box>
    );
};

export default MostBookedFlightsCard;
