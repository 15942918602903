import queryString from 'query-string';
import { HotelSearchDataType, PassengerRoomType, RoomPassengerType } from './search-fields-hotels.types';

export const generateSearchParamsHotel = (searchData: HotelSearchDataType): string => {
    const adt: string[] = [];
    const chd: string[] = [];
    const chdAges: string[] = [];
    searchData.roomPassenger.forEach((room, indexRoom) => {
        adt.push(room.passengers.adult.value.toString());
        chd.push(room.passengers.child.value.toString());
        chdAges.push(room.passengers.child.ages.map(age => age.value).join('-'));
    });
    const params = queryString.stringify(
        {
            [searchData.hotelCity.group === 'city' ? 'cityId' : 'hotelId']: searchData.hotelCity.value,
            [searchData.hotelCity.group === 'city' ? 'cityLabel' : 'hotelLabel']: searchData.hotelCity.label,
            checkIn: `${searchData.rangeDate.start?.year}-${searchData.rangeDate.start?.month}-${searchData.rangeDate.start?.day}`,
            checkOut: `${searchData.rangeDate.end?.year}-${searchData.rangeDate.end?.month}-${searchData.rangeDate.end?.day}`,
            adt,
            chd,
            chdAges,
        },
        { arrayFormat: 'index' }
    );
    return params;
};

export const isChildWithoutAge = (searchData: HotelSearchDataType) => {
    let response = false;
    searchData.roomPassenger.forEach(room => {
        if (room.passengers.child.value !== room.passengers.child.ages.length) {
            response = true;
        }
    });
    return response;
};
export const getAdultChildCountPerRoom = (value: RoomPassengerType[]) => {
    const passengers = value.reduce(
        (
            prevValue: {
                adult: PassengerRoomType;
                child: PassengerRoomType;
            }[],
            currentValue
        ) => prevValue.concat([currentValue.passengers]),
        []
    );
    const sum = passengers.reduce(
        (
            prevValue: number[],
            currentValue: {
                adult: PassengerRoomType;
                child: PassengerRoomType;
            }
        ) => {
            prevValue[0] = prevValue[0] + currentValue.adult.value;
            prevValue[1] = prevValue[1] + currentValue.child.value;
            return prevValue;
        },
        [0, 0]
    );
    return { adult: sum[0], child: sum[1] };
};
