import { Calendar } from './calender';
import { DateInterval, DateObject, DateRangeObject, DateYearMonth } from './type';

export function toDateObject(date: Date): DateObject {
    return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
    };
}

export function toDateString(date: DateObject, locale: string | undefined, format: 'long' | 'numeric' = 'long'): string {
    if (format === 'long') {
        return date ? new Date(date.year, date.month - 1, date.day).toLocaleString(locale, { weekday: 'long', day: 'numeric', month: 'long' }) : '';
    } else {
        return `${date.year}-${date.month - 1}-${date.day}`;
    }
}

export function toDate(date: DateObject): Date {
    return new Date(date.year, date.month - 1, date.day);
}

export function toDateObjectUTC(date: Date): DateObject {
    return {
        year: date.getUTCFullYear(),
        month: date.getUTCMonth(),
        day: date.getUTCDate(),
    };
}

export function toLocalDateRange(calendar: Calendar, dateRange: DateRangeObject): DateRangeObject {
    return {
        start: calendar.toLocalDateObject(dateRange.start),
        end: calendar.toLocalDateObject(dateRange.end),
    };
}

export function toLocalPartialDateRange(calendar: Calendar, dateRange: Partial<DateRangeObject>): Partial<DateRangeObject> {
    return {
        start: dateRange.start && calendar.toLocalDateObject(dateRange.start),
        end: dateRange.end && calendar.toLocalDateObject(dateRange.end),
    };
}

export function trimYear(year: number, monthCount: number, range: DateRangeObject) {
    return {
        startMonthIndex: year === range.start.year ? range.start.month : 1,
        endMonthIndex: year === range.end.year ? range.end.month : monthCount,
    };
}

export function trimMonth(year: number, month: number, dayCount: number, range: DateRangeObject) {
    if (
        isBeforeDate(range.start, {
            year,
            month,
            day: dayCount,
        }) ||
        isAfterDate(range.end, {
            year,
            month,
            day: 1,
        })
    ) {
        return {
            startDay: dayCount + 1,
            endDay: dayCount + 1,
        };
    }

    if (
        isBeforeDate(range.start, {
            year,
            month,
            day: dayCount,
        })
    ) {
        return {
            startDay: dayCount + 1,
            endDay: dayCount + 1,
        };
    }

    return {
        startDay: year === range.start.year && month === range.start.month ? range.start.day : 1,
        endDay: year === range.end.year && month === range.end.month ? range.end.day : dayCount,
    };
}

export function addMonthToYearMonth(date: DateYearMonth, months: number): DateYearMonth {
    let yearCalculated: number = date.year + Math.floor((date.month + months - 1) / 12);

    let monthCalculated: number = ((date.month + months - 1) % 12) + 1;
    if (monthCalculated < 1) {
        monthCalculated = 12 + monthCalculated;
    }

    return { year: yearCalculated, month: monthCalculated };
}

export function getDayOfMonth(year: number, month: number, date?: DateObject) {
    return date && isEqualDate(date, { year, month, day: date.day }) ? date.day : undefined;
}

export function isInDateRange(date: DateObject, dateRange: DateRangeObject) {
    return (isAfterDate(dateRange.start, date) || isEqualDate(dateRange.start, date)) && (isBeforeDate(dateRange.end, date) || isEqualDate(dateRange.end, date));
}

export function isAfterDate(baseDate: DateObject, compareDate: DateObject) {
    return !isBeforeDate(baseDate, compareDate) && !isEqualDate(baseDate, compareDate);
}

export function isEqualDate(baseDate: DateObject, compareDate: DateObject) {
    return baseDate.year === compareDate.year && baseDate.month === compareDate.month && baseDate.day === compareDate.day;
}

export function isBeforeDate(baseDate: DateObject, compareDate: DateObject) {
    if (baseDate.year > compareDate.year) {
        return true;
    } else if (baseDate.year === compareDate.year) {
        if (baseDate.month > compareDate.month) {
            return true;
        } else if (baseDate.month === compareDate.month) {
            if (baseDate.day > compareDate.day) {
                return true;
            }
        }
    }
    return false;
}

export function dateDifference(from: DateObject, to: DateObject): DateInterval {
    return {
        years: to.year - from.year,
        months: to.month - from.month,
        days: to.day - from.day,
    };
}
