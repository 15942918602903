import React from 'react';
import { ImageTitleSlideProps } from './image-title-slider';
import useDevice, { DeviceEnum } from '@travel-platform/commons/src/hook/use-device';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { TourStyleIntroductionCard } from './introduction-card';
import { CmsLandingComponentsTopActivity } from '@travel-platform/commons/src/types';

export const TourStyleContentTopAttractions: React.FC<CmsLandingComponentsTopActivity> = ({ title, items }) => {
    // Use Hook
    const isMobile = useDevice() === DeviceEnum.MOBILE;

    // Functions
    const generateData: ImageTitleSlideProps[] = React.useMemo(() => {
        return (
            items?.map(({ title, description, imageUrl }): ImageTitleSlideProps => {
                return {
                    title,
                    description,
                    imageUrl,
                    size: isMobile ? 'sm' : 'lg',
                };
            }) || []
        );
    }, [items]);

    return (
        <Stack
            spacing={{
                xs: 1,
                md: 2,
            }}>
            <Typography
                sx={{
                    typography: {
                        xs: 't3_bold',
                        md: 't2_bold',
                    },
                }}>
                {title}
            </Typography>
            <Box>
                <Grid
                    container
                    spacing={3}>
                    {generateData.map((item, i) => (
                        <Grid
                            key={i}
                            xs={12}
                            md={6}
                            item>
                            <TourStyleIntroductionCard {...item} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Stack>
    );
};
