import { useAppSelector } from '../../../redux/hooks';
import { SupportedCurrency } from '@travel-platform/commons/src/types';

export function useExchangeCurrency() {
    // Use Hooks

    // Global State
    const currency = useAppSelector(state => state.currency.currency);
    const exchangeRate = useAppSelector(state => state.exchangeRate.exchangeRate);

    const exchangePrice = (price: number | string, fromCurrency: SupportedCurrency, toCurrency: SupportedCurrency = currency) => {
        if (exchangeRate.rates) {
            const priceToUSD = Number(price) / exchangeRate.rates[fromCurrency];
            const priceToCurrentCurrency = priceToUSD * exchangeRate.rates[toCurrency];
            return priceToCurrentCurrency;
        } else {
            return 0;
        }
    };

    return { exchangePrice, currency };
}
