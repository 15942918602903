import queryString from 'query-string';
import { ParamsDataType } from '../flights.types';
import { FlightSearchData, PassengersFlightSearchDataType } from './search-fields-flights.types';

export const generateSearchParamsFlight = (searchData: FlightSearchData): string => {
    const paramsData: ParamsDataType = {
        adult: searchData.passengers.adult.toString(),
        child: searchData.passengers.child.toString(),
        infant: searchData.passengers.infant.toString(),
        flightClass: searchData.flightClass,
        tripType: searchData.tripType,
        start: `${searchData.rangeDate.start.year}-${searchData.rangeDate.start.month}-${searchData.rangeDate.start.day}`,
        originCode: searchData.originDestination.origin.code,
        originType: searchData.originDestination.origin.type,
        originLabel: searchData.originDestination.origin.label,
        originCountry: searchData.originDestination.origin.country,
        destinationCode: searchData.originDestination.destination.code,
        destinationType: searchData.originDestination.destination.type,
        destinationLabel: searchData.originDestination.destination.label,
        destinationCountry: searchData.originDestination.destination.country,
    };
    if (searchData.rangeDate.end) {
        paramsData.end = `${searchData.rangeDate.end.year}-${searchData.rangeDate.end.month}-${searchData.rangeDate.end.day}`;
    }
    const params = queryString.stringify(paramsData, { arrayFormat: 'index' });
    return params;
};
export const isDisabledIncreasePassengerCounter = (passengers: PassengersFlightSearchDataType, index: number): boolean => {
    switch (index) {
        case 0:
            return !(passengers.adult < 9 && passengers.adult + passengers.child < 9);
        case 1:
            return !(passengers.child < 8 && passengers.adult + passengers.child < 9);
        case 2:
            return !(passengers.infant < passengers.adult);
        default:
            return false;
    }
};
export const isDisabledDecreasePassengerCounter = (passengers: PassengersFlightSearchDataType, index: number): boolean => {
    switch (index) {
        case 0:
            return !(passengers.adult > 1 && passengers.adult > passengers.infant);
        case 1:
            return !(passengers.child > 0);
        case 2:
            return !(passengers.infant > 0);
        default:
            return false;
    }
};
