import { CancelToken } from 'axios';
import { Api, Method } from '@travel-platform/commons/src/services/api';
import {
    CreateReservationRequest,
    CreateReservationResponse,
    GetAllHotelProvidersRequest,
    GetAllHotelProvidersResponse,
    GetCitySearchFiltersRequest,
    GetCitySearchFiltersResponse,
    GetCitySearchResultRequest,
    GetCitySearchResultResponse,
    GetHotelInfoListRequest,
    GetHotelInfoListResponse,
    GetHotelInfoRequest,
    GetHotelInfoResponse,
    GetHotelProviderTypesResponse,
    GetHotelSearchResultRequest,
    GetHotelSearchResultResponse,
    GetReservationByIdRequest,
    GetReservationByIdResponse,
    GetReservationByTokenRequest,
    GetReservationByTokenResponse,
    SearchAvailableHotelInACityRequest,
    SearchAvailableHotelInACityResponse,
    SearchCityNamesRequest,
    SearchCityNamesResponse,
    SearchHotelNamesRequest,
    SearchHotelNamesResponse,
    SearchHotelRoomsAvailableRequest,
    SearchHotelRoomsAvailableResponse,
    UpdateHotelProviderRequest,
    UpdateHotelProviderResponse,
    UpdatePlacesRequest,
    ValidateOfferRequest,
    ValidateOfferResponse,
} from './hotels.types';

export const apiSearchHotelNames = (params: SearchHotelNamesRequest | null, cancelToken: CancelToken) => {
    return Api<SearchHotelNamesResponse[]>(Method.GET, '/hotel/place/search/hotels', params, null, cancelToken);
};
export const apiSearchCityNames = (params: SearchCityNamesRequest | null, cancelToken: CancelToken) => {
    return Api<SearchCityNamesResponse[]>(Method.GET, '/hotel/place/search/cities', params, null, cancelToken);
};
export const apiSearchAvailableHotelInACity = (body: SearchAvailableHotelInACityRequest | null, token?: string) => {
    return Api<SearchAvailableHotelInACityResponse>(Method.POST, '/hotel/hotels/search/city/', null, body, undefined, token);
};
export const apiGetCitySearchResult = (params: GetCitySearchResultRequest, token?: string) => {
    return Api<GetCitySearchResultResponse>(Method.GET, '/hotel/hotels/search/city/', params, null, undefined, token);
};
export const apiSearchHotelRoomsAvailable = (body: SearchHotelRoomsAvailableRequest | null) => {
    return Api<SearchHotelRoomsAvailableResponse>(Method.POST, '/hotel/hotels/search/hotel/', null, body);
};
export const apiGetHotelSearchResult = (params: GetHotelSearchResultRequest) => {
    return Api<GetHotelSearchResultResponse[]>(Method.GET, '/hotel/hotels/search/hotel/result/', params, null);
};
export const apiValidateOffer = (params: ValidateOfferRequest) => {
    return Api<ValidateOfferResponse>(Method.GET, `/hotel/hotels/offer/validate/`, params, null);
};
export const apiCreateReservation = (body: CreateReservationRequest, token: string | null) => {
    return Api<CreateReservationResponse>(Method.POST, `/hotel/hotels/product`, null, body, undefined, token);
};
export const apiGetReservationById = (params: GetReservationByIdRequest, token: string | null) => {
    return Api<GetReservationByIdResponse[]>(Method.GET, `/hotel/reservations`, params, null, undefined, token);
};
export const apiGetReservationByToken = (params: GetReservationByTokenRequest) => {
    return Api<GetReservationByTokenResponse>(Method.GET, `/hotel/reservations/token/`, params, null);
};
export const apiGetHotelInfo = (params: GetHotelInfoRequest) => {
    return Api<GetHotelInfoResponse>(Method.GET, `/hotel/place/hotel/info/`, params, null);
};
export const apiGetCitySearchFilters = (params: GetCitySearchFiltersRequest) => {
    return Api<GetCitySearchFiltersResponse>(Method.GET, `/hotel/hotels/search/city/filters`, params, null);
};
export const apiGetHotelProviderTypes = (token: string | null) => {
    return Api<GetHotelProviderTypesResponse>(Method.GET, `/hotel/provider/types`, null, null, undefined, token);
};
export const apiGetAllHotelProviders = (params: GetAllHotelProvidersRequest, token: string | null) => {
    return Api<GetAllHotelProvidersResponse[]>(Method.GET, `/hotel/provider`, params, null, undefined, token);
};
export const apiUpdateHotelProvider = (id: number, body: UpdateHotelProviderRequest, token: string | null) => {
    return Api<UpdateHotelProviderResponse>(Method.PATCH, `/hotel/provider/${id}`, null, body, undefined, token);
};
export const apiCreateHotelProvider = (body: UpdateHotelProviderRequest, token: string | null) => {
    return Api<UpdateHotelProviderResponse>(Method.POST, `/hotel/provider`, null, body, undefined, token);
};
export const apiRemoveHotelProviders = (id: number, token?: string | null) => {
    return Api<GetAllHotelProvidersResponse>(Method.DELETE, `/hotel/provider/${id}`, null, null, undefined, token);
};
export const apiUpdatePlaces = (params: UpdatePlacesRequest, token: string | null) => {
    return Api<any>(Method.GET, `/hotel/place/update-places`, params, null, undefined, token);
};
export const apiGetHotelInfoList = (params: GetHotelInfoListRequest) => {
    return Api<GetHotelInfoListResponse>(Method.GET, '/hotel/place/hotel/info/list', params, null);
};
